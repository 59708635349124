.card-wrapper {
	background: transparent;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 30px;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	height: 400px;
	float: left;
	color: white;
}
.img-wrapper {
	height: 256px;
	width: 100%;
}
.card-wrapper img {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	object-fit: cover;
	top: 0;
}

.card-descrip {
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
	border-radius: 0 0 2px 2px;
	border-top: none;
	padding: 30px;
	position: relative;
	z-index: 11;
	display: block;
	text-align: center;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.25);
}
.card-descrip a {
	color: white;
	font-size: 25px;
}
.card-descrip a {
	color: white !important;
	text-decoration: none;
}
.card-descrip a:hover {
	text-decoration: underline;
	color: white !important;
}
.card-descrip h3 {
	margin: 0;
}
.card-descrip p {
	margin-top: 5px;
}
.card-title {
	height: 40px;
    width: 100%;
    box-sizing: border-box;
}

.card-modal-img {
	cursor: pointer;
}
