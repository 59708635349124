/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    margin: 0 5%;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
	border-radius: 4px;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}
.slick-trac {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track  {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots li button {
    cursor: pointer;
    color: transparent;
    background: transparent;
    border: none;
    position: relative;
    left: 0px;
 }
 .slick-dots li button::before {
    display: none;
 }
 .slick-dots li  {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #5c5c5c;
    float: left;
    margin: 0px 3px;
 }
 .slick-dots {
    width: max-content;
    margin: auto;
 }
 .slick-dots ::marker {
     color:transparent;
 }
 li.slick-active {
     background-color: #a7a7a7;
     border-radius: 40%;
 }
.arrow {
    width: 41px;
    background: #d5d5d5;
    height: 41px;
    position: absolute;
    top: 45%;
    border-radius: 50%;
}
.right-arrow {
    right: -35px;
}
.left-arrow {
    left: -35px;
}
.carousel-wrap {
    width:90%;
    margin:0px auto;
}
.carousel-wrapper {
    display:block;
    margin:0px auto;
    width:100%;
}

@media screen and (min-width: 280px) and (max-width: 768px)  {
    .right-arrow {
        right: 20px;
        display: none;
    }
    .left-arrow {
        left: 20px;
        z-index: 1;
        display: none;
    }
    .carousel-wrap {
        margin: 0px 10px 0px;
        width: 95%
    }
}

@media screen and (min-width: 280px) and (max-width: 768px) and (orientation: landscape)  {
    .right-arrow {
        display: none;
    }
    .left-arrow {
        display: none;
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .right-arrow {
        display: none;
    }
    .left-arrow {
        display: none;
    }
    .carousel-wrap {
        margin: 0px 25px 0px;
        width: 95%;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape)  {
    .right-arrow {
        display: none;
    }
    .left-arrow {
        display: none;
    }
}

@media screen and (min-width:501px) and (max-width:999px) {
    .carousel-wrap {
        margin: 0px 20px 0px;
        width: 95%
    }
 }
 @media screen and (min-width:1440px){
    .carousel-wrapper {
      width:90%;
    }
 }
