.pillar-tab {
    display: flex;
    flex-wrap: wrap;
    color: white;
    justify-content: center;
    margin-top: 50px;
}
.pillar-tab span {
    padding: 10px 5px;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}
.pillar-tab  div {
    text-align: center;
    padding-right: 20px;
}
.pillar-tab  div:not(:last-child) {
    border-right: 1px solid white;
}
.pillar-tab .active {
    border-bottom: 2px solid #66f268;
    padding-bottom: 4px;
}

@media screen and (min-width: 280px) and (max-width: 480px)  {
    .pillar-tab  div {
        margin-bottom: 10px;
    }
    .pillar-tab {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        overflow: auto;
        height: 60px;
        margin-top: 30px;
    }
    .pillar-tab span {
        font-size: 16px;
    }
    .pillar-tab  div {
        padding-right: 8px;
    }
    .pillar-tab span {
        margin-left: 8px;
    }
}
